.login-wrap {
    transition: 0.5s;
    /* Safari */
    -webkit-transition: 0.5s;
}

.login-logo {
    text-align: center;
}

.login-logo img {
    margin-bottom: 120px;
    width: 90%;
    max-width: 420px;
}

.login-main {
    background: none;
    border: none;
    box-shadow: none;
    width: 90%;
    max-width: 420px;
}

.login-main .layui-form-item {
    margin-bottom: 5px;
}

.login-main .layui-form-item input {
    padding-left: 0;
}

.login-main input,
.login-main button {
    border-radius: 5px;
    background-color: #fff;
    color: #294bbe;
    text-align: center;
}

.login-main .layui-form-checkbox span {
    color: #fff;
}

.layui-form-checked[lay-skin=primary] i {
    background-color: #fff;
    color: #294bbe;
}

.layui-form-checked[lay-skin=primary] i:hover {
    background-color: #fff;
    color: #fff;
}

.layui-form-checkbox[lay-skin=primary]:hover i {
    background-color: #294bbe;
    color: #fff;
}

@media screen and (max-width: 760px) {
    /* .login-wrap {
        margin-top: 30%;
    } */
}